// MortgagesList.js

import React, { useState, useEffect } from "react";
import { calculateAllMortgages } from "../services/apiService";
import "./MortgagesList.css";

const MortgagesList = () => {
  const [mortgages, setMortgages] = useState();
  const [isLoading, setIsLoading] = useState(true); // New state variable

  useEffect(() => {
    calculateAllMortgages()
      .then((response) => {
        console.log(response);
        setMortgages(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("Done");
        setIsLoading(false); // Set loading to false when done
      });
  }, []);

  if (isLoading) {
    return <p>Loading...</p>; // Show loading message while data is loading
  }

  if (mortgages && mortgages.length > 0) {
    return (
      <table className="table">
        <thead className="thead">
          <tr>
            <th className="th">Entity</th>
            <th className="th">Description</th>
            <th className="th">Amount</th>
            <th className="th">Interest Rate</th>
            <th className="th">Term</th>
          </tr>
        </thead>
        <tbody>
          {mortgages.map((mortgage) => (
            <tr key={mortgage.id}>
              <td className="td">{mortgage.entity}</td>
              <td className="td">{mortgage.description}</td>
              <td className="td">{mortgage.amount}</td>
              <td className="td">{mortgage.interestRate}</td>
              <td className="td">{mortgage.term}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
};

export default MortgagesList;
