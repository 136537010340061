import { Persona } from "../models/Persona";
import { Hipoteca } from "../models/Hipoteca";

const API_URL = "http://localhost:1800";

export const createProperty = async (property) => {
  const response = await fetch(`${API_URL}/properties`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(property),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const getProperties = async () => {
  const response = await fetch(`${API_URL}/properties`,);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const getPerson = (id) => {
  // Crear una instancia de Persona
  const persona = new Persona("Miguel Ángel Molina");

  // Agregar ingresos e hipotecas
  persona.agregarIngresoPorSalario(2668);
  persona.agregarIngresoPorRenta(597);
  persona.agregarHipoteca(new Hipoteca(67880, 132, 1.09, "2020-10-28"));

  return persona
};

export const calculateAllMortgages = async () => {
  const response = await fetch(`${API_URL}/mortgages/calculate-all`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
};

