import React from "react";
import ExampleCalculations from "./ExampleCalculations";

const Santander = () => {
  const interestRates1 = [2.85];

  // const principal2 = precio * 0.9;
  // const interestRates2 = [3.05];

  // const interestRates = [1.4, 2.5, 2.7, 3];
  // const interestRates = [2.35, 2.9, 3.05];

  const terms = [ 25];
  // const multipliedTerms = terms.map((term) => term * 12);

  return (
    <>
      <ExampleCalculations
        precio={56000}
        interestRates={interestRates1}
        terms={terms.map((term) => term * 12)}
      />
      <ExampleCalculations
        precio={64000}
        interestRates={interestRates1}
        terms={terms.map((term) => term * 12)}
      />
      <ExampleCalculations
        precio={80000}
        interestRates={interestRates1}
        terms={terms.map((term) => term * 12)}
      />

    </>
  );
};
export default Santander;
