// PropertyList.js

import React, { useState, useEffect } from "react";
import { getProperties } from "../services/apiService";

const PropertyList = () => {
  const [properties, setProperties] = useState();
  const [isLoading, setIsLoading] = useState(true); // New state variable

  useEffect(() => {
    getProperties()
      .then((response) => {
        console.log(response);
        setProperties(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("Done");
        setIsLoading(false); // Set loading to false when done
      });
  }, []);

  if (isLoading) {
    return <p>Loading...</p>; // Show loading message while data is loading
  }
  
  if (properties && properties.length > 0) {
    return <div>{ <p>No properties found</p>}</div>;
  }
};

export default PropertyList;
