import React from "react";
import ExampleCalculations from "./ExampleCalculations";

const AmortizacionCiempo = () => {
  return (
    <>
      {/* <ExampleCalculations precio={70000} /> */}
      <ExampleCalculations
        precio={46654.31}
        interestRates={[1.09]}
        terms={[89]}
      />
      <ExampleCalculations
        precio={45654.31}
        interestRates={[1.09]}
        terms={[89]}
      />
      <ExampleCalculations
        precio={44654.31}
        interestRates={[1.09]}
        terms={[89]}
      />
      <ExampleCalculations
        precio={43654.31}
        interestRates={[1.09]}
        terms={[89]}
      />
      <ExampleCalculations
        precio={42654.31}
        interestRates={[1.09]}
        terms={[89]}
      />

      <ExampleCalculations
        precio={40654.31}
        interestRates={[1.09]}
        terms={[89]}
      />

      <ExampleCalculations
        precio={36654.31}
        interestRates={[1.09]}
        terms={[89]}
      />

      {/* <ExampleCalculations precio={80000} /> */}
    </>
  );
};
export default AmortizacionCiempo;
