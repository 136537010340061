import React from "react";

import { calculateAllMortgages } from "../services/apiService";
import ExampleCalculations from "./ExampleCalculations";


const mortgages = [
  { entidad: "Entidad 1", descripcion: "Descripción 1", principal: 56000, interestRate: 2.4, terms: [15, 20, 25] },
  { entidad: "Entidad 1", descripcion: "Descripción 1", principal: 56000, interestRate: 2.6, terms: [15, 20, 25] },
  { entidad: "Entidad 1", descripcion: "Descripción 1", principal: 56000, interestRate: 2.9, terms: [15, 20, 25] },
  { entidad: "Entidad 1", descripcion: "Descripción 1", principal: 56000, interestRate: 3.05, terms: [15, 20, 25] },
];

const EvoMixta5anos = () => {
  return (
    <>
      {mortgages.map((mortgage, index) => (
        <ExampleCalculations
          key={index}
          precio={mortgage.principal}
          interestRates={[mortgage.interestRate]}
          terms={mortgage.terms.map((term) => term * 12)}
          entidad={mortgage.entidad}
          descripcion={mortgage.descripcion}
        />
      ))}
    </>
  );
};

export default EvoMixta5anos;
