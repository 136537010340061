import React from "react";
import Calculations from "./Calculations";

import { calculateAmortizationForTerms } from "../services/amortizationService";

import { formatCurrency } from "../utils/formatting";

import "../styles/ExampleCalculations.css";

const ExampleCalculations = ({ entidad, descripcion, precio, interestRates, terms }) => {
  const principal1 = precio;

  return (
    <div className="container">
      <h3>
        {entidad}, {descripcion}: {formatCurrency(principal1)}
      </h3>
      {interestRates.map((rate, index) => (
        <div key={index}>
          <div>
            Calculations for Interest Rate <b>{rate} %</b>
          </div>
          <Calculations
            principal={principal1}
            interestRate={rate}
            terms={terms}
          />
        </div>
      ))}
    </div>
  );
};
export default ExampleCalculations;
