import React from "react";
import ExampleCalculations from "./ExampleCalculations";

const Ibercaja = () => {
  const interestRates1 = [2.25];

  const terms = [15, 20, 25, 30];

  return (
    <>
      <ExampleCalculations
        precio={56000}
        interestRates={interestRates1}
        terms={terms.map((term) => term * 12)}
      />
    </>
  );
};
export default Ibercaja;
