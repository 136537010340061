// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import PropertyList from "./components/PropertyList";
import MortgagesList from "./components/MortgagesList";
import Todas from "./components/Todas";
import AllFromApi from "./components/AllFromApi";

import ExampleCalculations from "./components/ExampleCalculations";
import AmortizationCalculator from "./components/AmortizationCalculator";
import SomePrices from "./components/SomePrices";
import Jueves from "./components/Jueves";
import Abanca7080 from "./components/Abanca7080";
import Abanca708090 from "./components/Abanca708090";
import EvoMixta5anos from "./components/EvoMixta5anos";
import Colibid from "./components/Colibid";
import SabadellOnline from "./components/SabadellOnline";
import SabadellNarejos from "./components/SabadellNarejos";
import Globalcaja from "./components/Globalcaja";
import Bbva from "./components/Bbva";
import AmortizacionCiempo from "./components/AmortizacionCiempo";
import CaixabankSanjavier from "./components/CaixabankSanjavier";
import Santander from "./components/Santander";
import Ibercaja from "./components/Ibercaja";
import Home from "./components/Home"; // Import your Home component

import Persona from "./components/Persona";

import "./App.css";

const interestRates = [2.99, 2.9, 2.8, 2.65, 2.35];
// const terms = [25, 24, 23, 22, 21, 20, 16, 15];
const terms = [30, 25, 20, 15];

function App() {
  return (
    <Router>
      <div className="App">
        <div className="sidebar">
          <Link to="/">Home</Link>
          <Link to="/todas">Todas</Link>
          <Link to="/all-from-api">All From Api</Link>
          <Link to="/calculator">Calculator</Link>
          <Link to="/mortgage-list">MortgagesList</Link>
          <Link to="/property-list">PropertyList</Link>
          <Link to="/example">Example</Link>
          <Link to="/someprices">Some Prices</Link>
          <Link to="/jueves">Jueves</Link>
          <Link to="/abanca7080">Abanca 70K / 80K</Link>
          <Link to="/abanca708090">Abanca 70% / 80% /90% </Link>
          <Link to="/bbva">Bbva</Link>
          <Link to="/colibid">Colibid</Link>
          <Link to="/evomixta5">Evo Mixta 5 años</Link>
          <Link to="/globalcaja">Globalcaja</Link>
          <Link to="/sabadellonline">Sabadell Online</Link>
          <Link to="/sabadell_narejos">Sabadell Narejos</Link>
          <Link to="/caixabanksanjavier">Caixabank San javier</Link>
          <Link to="/amortizacionciempo">Amortizacion Ciempo</Link>
          <Link to="/persona">Persona</Link>
          <Link to="/santander">Santander</Link>
          <Link to="/ibercaja">Ibercaja</Link>
        </div>
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/todas" element={<Todas />} />
            <Route path="/all-from-api" element={<AllFromApi />} />
            <Route path="/mortgage-list" element={<MortgagesList />} />
            <Route path="/property-list" element={<PropertyList />} />
            <Route path="/calculator" element={<AmortizationCalculator />} />
            <Route
              path="/example"
              element={
                <ExampleCalculations
                  precio={64000}
                  interestRates={interestRates}
                  terms={terms.map((term) => term * 12)}
                />
              }
            />
            <Route path="/someprices" element={<SomePrices />} />
            <Route path="/jueves" element={<Jueves />} />
            <Route path="/abanca7080" element={<Abanca7080 />} />
            <Route path="/abanca708090" element={<Abanca708090 />} />
            <Route path="/evomixta5" element={<EvoMixta5anos />} />
            <Route path="/colibid" element={<Colibid />} />
            <Route path="/sabadellonline" element={<SabadellOnline />} />
            <Route path="/sabadell_narejos" element={<SabadellNarejos />} />
            <Route path="/globalcaja" element={<Globalcaja />} />
            <Route path="/bbva" element={<Bbva />} />
            <Route path="/santander" element={<Santander />} />
            <Route path="/ibercaja" element={<Ibercaja />} />
            <Route
              path="/caixabanksanjavier"
              element={<CaixabankSanjavier />}
            />
            <Route
              path="/amortizacionciempo"
              element={<AmortizacionCiempo />}
            />
            <Route path="/persona" element={<Persona />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
