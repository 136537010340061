import React from 'react';

import { formatCurrency } from "../utils/formatting";

import '../styles/AmortizationSchedule.css';

const AmortizationSchedule = ({ amortizationSchedule, filter = false }) => {
      let cumulativeInterest = 0;

      return (
        <div className="schedule-container">
          <div className="schedule-row header">
            <div>Mes</div>
            <div>Pago Mensual</div>
            <div>Pago Principal</div>
            <div>Pago Interés</div>
            <div>Interés Acumulado</div>
            <div>Principal Acumulado</div>
            <div>Balance</div>
          </div>
          {amortizationSchedule.map((month, index) => {
            cumulativeInterest += Number(month.interestPayment);
            cumulativeInterest = Number(cumulativeInterest.toFixed(2));
            if (filter && index+1 !== filter) {
              return null;
            }
            return (
              <div key={index} className="schedule-row">
                <div>{index + 1}</div>
                <div>{formatCurrency(month.monthlyPayment)}</div>
                <div>{formatCurrency(month.principalPayment)}</div>
                <div>{formatCurrency(month.interestPayment)}</div>
                <div>{formatCurrency(cumulativeInterest)}</div>
                <div>{formatCurrency(month.accumulatedPrincipal)}</div>
                <div>{formatCurrency(month.balance)}</div>
              </div>
            );
          })}
        </div>
      );
};

export default AmortizationSchedule;