// amortizationService.js

export const calculateAmortization = (principal, interestRate, term) => {
  let monthlyInterestRate = interestRate / 100 / 12;
  let numberOfPayments = term;
  let monthlyPayment =
    (principal * monthlyInterestRate) /
    (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
  let totalPayment = monthlyPayment * numberOfPayments;
  let totalInterest = totalPayment - principal;

  let balance = principal;
  let amortizationSchedule = [];
  let accumulatedPrincipal = 0; // Inicializar el monto acumulado de principal

  for (let i = 1; i <= numberOfPayments; i++) {
    let interestPayment = balance * monthlyInterestRate;
    let principalPayment = monthlyPayment - interestPayment;
    balance -= principalPayment;
    accumulatedPrincipal += principalPayment; // Sumar el pago de principal al monto acumulado

    amortizationSchedule.push({
      month: i,
      monthlyPayment: monthlyPayment.toFixed(2),
      principalPayment: principalPayment.toFixed(2),
      interestPayment: interestPayment.toFixed(2),
      balance: balance.toFixed(2),
      accumulatedPrincipal: accumulatedPrincipal.toFixed(2), // Añadir el monto acumulado al cronograma
    });
  }

  return {
    monthlyPayment: monthlyPayment.toFixed(2),
    totalPayment: totalPayment.toFixed(2),
    totalInterest: totalInterest.toFixed(2),
    amortizationSchedule: amortizationSchedule,
  };
};

export const calculateAmortizationForTerms = (principal, interestRate, terms) => {
  const amortizationData = [];
  terms.forEach(term => {
    const amortization = calculateAmortization(principal, interestRate, term);
    amortizationData.push({
      term: term,
      monthlyPayment: amortization.monthlyPayment,
      totalPayment: amortization.totalPayment,
      totalInterest: amortization.totalInterest,
      amortizationSchedule: amortization.amortizationSchedule
    });
  });
  return amortizationData;
};