import React from "react";
import ExampleCalculations from "./ExampleCalculations";

const Abanca7080 = () => {
  const interestRates1 = [2.9];
  const interestRates2 = [3.05];

  // const principal2 = precio * 0.9;
  // const interestRates2 = [3.05];

  // const interestRates = [1.4, 2.5, 2.7, 3];
  // const interestRates = [2.35, 2.9, 3.05];

  const terms = [15, 18,19, 20, 25, 30];
  // const multipliedTerms = terms.map((term) => term * 12);

  return (
    <>
      <ExampleCalculations
        precio={64000}
        interestRates={interestRates1}
        terms={terms.map((term) => term * 12)}
      />
      {/* <ExampleCalculations
        precio={600000}
        interestRates={interestRates}
        terms={terms.map((term) => term * 12)}
      /> */}
      <ExampleCalculations
        precio={63000}
        interestRates={interestRates2}
        terms={terms.map((term) => term * 12)}
      />
    </>
  );
};
export default Abanca7080;
