import React from "react";
import ExampleCalculations from "./ExampleCalculations";

const EvoMixta5anos = () => {
  const interestRates1 = [2.4, 2.6];
  const interestRates2 = [2.9];
  const interestRates3 = [3.05];

  // const principal2 = precio * 0.9;
  // const interestRates2 = [3.05];

  // const interestRates = [1.4, 2.5, 2.7, 3];
  // const interestRates = [2.35, 2.9, 3.05];

  const terms1 = [15, 16, 17, 18, 19, 20];
  const terms2 = [20];
  // const multipliedTerms = terms.map((term) => term * 12);

  return (
    <>
      <ExampleCalculations
        precio={56000}
        interestRates={interestRates1}
        terms={terms1.map((term) => term * 12)}
      />

      <ExampleCalculations
        precio={64000}
        interestRates={interestRates1}
        terms={terms2.map((term) => term * 12)}
      />

    </>
  );
};
export default EvoMixta5anos;
