import React, { useState } from "react";
import { calculateAmortization } from "../services/amortizationService";

import "../styles/AmortizationCalculator.css";
import PropertyList from "./PropertyList";

function AmortizationCalculator() {
  const [principal, setPrincipal] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [term, setTerm] = useState("");
  const [results, setResults] = useState(null);

  const calculate = (a, b, c) => {
    console.log(a);
    console.log(b);
    console.log(c);
    const results = calculateAmortization(a, b, c);
    console.log(results);
    setResults(results);
  };

  return (
    <div>
      <PropertyList />
      <h1>Calculadora de Amortización</h1>
      <input
        type="number"
        placeholder="Principal"
        value={principal}
        onChange={(e) => setPrincipal(e.target.value)}
      />
      <input
        type="number"
        placeholder="Tasa de Interés"
        value={interestRate}
        onChange={(e) => setInterestRate(e.target.value)}
      />
      <input
        type="number"
        placeholder="Plazo (en años)"
        value={term}
        onChange={(e) => setTerm(e.target.value)}
      />
      <button onClick={() => calculate(principal, interestRate, term)}>
        Calcular
      </button>
      {results && (
        <div className="results">
          <h2 className="results-title">Resultados</h2>
          <p className="monthly-payment">
            Pago mensual: {results.monthlyPayment}
          </p>
          <p className="total-payment">Pago total: {results.totalPayment}</p>
          <p className="total-interest">
            Interés total: {results.totalInterest}
          </p>
          <h3 className="amortization-title">Tabla de Amortización</h3>
          <div className="amortizationSchedule">
            <div className="amortizationMonth">
              <p>Mes</p>
              <p>Pago mensual</p>
              <p>Pago principal</p>
              <p>Pago de intereses</p>
              <p>Saldo</p>
            </div>
            {results.amortizationSchedule.map((month, index) => (
              <div key={index} className="amortizationMonth">
                <p>{month.month}</p>
                <p>{month.monthlyPayment}</p>
                <p>{month.principalPayment}</p>
                <p>{month.interestPayment}</p>
                <p>{month.balance}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AmortizationCalculator;
