import React from "react";
import ExampleCalculations from "./ExampleCalculations";

const SomePrices = () => {

    const interestRates1 = [2.99, 2.9, 2.65, 2.35];
    const interestRates2 = [2.72, 2.69, 2.66];

    // const principal2 = precio * 0.9;
    // const interestRates2 = [3.05];

    // const interestRates = [1.4, 2.5, 2.7, 3];
    // const interestRates = [2.35, 2.9, 3.05];

    const terms = [25, 24, 23, 22, 21, 20, 16, 15];
    // const multipliedTerms = terms.map((term) => term * 12);

  return (
    <>
      <ExampleCalculations
        precio={60000}
        interestRates={interestRates1}
        terms={terms.map((term) => term * 12)}
      />
      <ExampleCalculations
        precio={56000}
        interestRates={interestRates2}
        terms={terms.map((term) => term * 12)}
      />
    </>
  );
};
export default SomePrices;
