export class Persona {
  constructor(nombre) {
    this.nombre = nombre;
    this.ingresosPorSalarios = [];
    this.ingresosPorRentas = [];
    this.hipotecas = [];
  }

  agregarIngresoPorSalario(ingreso) {
    this.ingresosPorSalarios.push(ingreso);
  }

  agregarIngresoPorRenta(ingreso) {
    this.ingresosPorRentas.push(ingreso);
  }

  agregarHipoteca(hipoteca) {
    this.hipotecas.push(hipoteca);
  }

  obtenerIngresosTotales() {
    const totalSalarios = this.ingresosPorSalarios.reduce((acc, curr) => acc + curr, 0);
    const totalRentas = this.ingresosPorRentas.reduce((acc, curr) => acc + curr, 0);
    return totalSalarios + totalRentas;
  }
  obtenerTotalSalarios() {
    return this.ingresosPorSalarios.reduce((acc, curr) => acc + curr, 0);
  }

  obtenerTotalRentas() {
    return this.ingresosPorRentas.reduce((acc, curr) => acc + curr, 0);
  }

  obtenerRatioEndeudamiento() {
    const totalHipotecas = this.hipotecas.reduce((acc, curr) => acc + curr, 0);
    const totalSalarios = this.ingresosPorSalarios.reduce((acc, curr) => acc + curr, 0)
    console.log("totalHipotecas " + totalHipotecas);
    console.log("totalSalarios " + totalSalarios);
    return totalHipotecas / totalSalarios;
  }

}