import React from "react";
import Calculations from "./Calculations";

import { getPerson } from "../services/apiService";

import { formatCurrency } from "../utils/formatting";

import "../styles/Global.css";

const Persona = () => {
  const person = getPerson(1);

  return (
    <div className="container">
      <h3>{person.nombre}</h3>
      <div className="container">
        <div className="header">
          <div>Ingresos totales</div>
          <div>Ingresos por nómina</div>
          <div>Ingresos por rentas</div>
          <div>Pagos préstamos</div>
        </div>
        <div className="row">
          <div>{person.obtenerIngresosTotales()}</div>
          <div>{person.obtenerTotalSalarios()}</div>
          <div>{person.obtenerTotalRentas()}</div>
          <div>{person.obtenerRatioEndeudamiento()}</div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Persona;
