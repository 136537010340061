import React, { useState } from "react";
import AmortizationSchedule from "./AmortizationSchedule";

import { calculateAmortizationForTerms } from "../services/amortizationService";

import { formatCurrency } from "../utils/formatting";

// import "../styles/Calculations.css";

const Calculations = ({ principal, interestRate, terms, filter = false }) => {
  const [visibleSchedules, setVisibleSchedules] = useState({});

  const toggleSchedule = (term) => {
    setVisibleSchedules((prevState) => ({
      ...prevState,
      [term]: !prevState[term],
    }));
  };
  
  const amortizationData = calculateAmortizationForTerms(
    principal,
    interestRate,
    terms
  );
  console.log(amortizationData);

  return (
    <div className="container">
      <div className="header">
        <div>Plazo</div>
        <div>Pago Mensual</div>
        <div>Intereses Totales</div>
        <div>Pago Total</div>
      </div>
      {amortizationData.map((data) => (
        <div key={data.term}>
          <div className="row" onClick={() => toggleSchedule(data.term)}>
            {/* <div>{data.term} {data.term === 1 ? 'mes' : 'meses'} ({data.term/12} {data.term === 1 ? 'año' : 'años'})</div> */}
            <div>{data.term/12} {data.term === 1 ? 'año' : 'años'}</div>
            <div>{formatCurrency(data.monthlyPayment)}</div>
            <div>{formatCurrency(data.totalInterest)}</div>
            <div>{formatCurrency(data.totalPayment)}</div>
          </div>
          {!visibleSchedules[data.term] && filter && (
            <AmortizationSchedule
              filter={filter}
              amortizationSchedule={data.amortizationSchedule}
            />
          )}
          {visibleSchedules[data.term] && (
            <AmortizationSchedule
              amortizationSchedule={data.amortizationSchedule}
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default Calculations;
