// AllFromApi.js

import React, { useState, useEffect } from "react";
import { calculateAllMortgages } from "../services/apiService";
import ExampleCalculations from "./ExampleCalculations";

import "./AllFromApi.css";

const AllFromApi = () => {
  const [mortgages, setMortgages] = useState();
  const [isLoading, setIsLoading] = useState(true); // New state variable

  useEffect(() => {
    calculateAllMortgages()
      .then((response) => {
        console.log(response);
        setMortgages(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("Done");
        setIsLoading(false); // Set loading to false when done
      });
  }, []);

  if (isLoading) {
    return <p>Loading...</p>; // Show loading message while data is loading
  }

  if (mortgages && mortgages.length > 0) {
    return (
      <>
        {mortgages.map((mortgage) => (
          <ExampleCalculations
            key={mortgage.id}
            entidad={mortgage.entity}
            descripcion={mortgage.description}
            precio={mortgage.amount}
            interestRates={[mortgage.interestRate]}
            terms={[mortgage.term * 12]}
          />
        ))}
      </>
    );
  }
};

export default AllFromApi;
